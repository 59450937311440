import React, { useState, useEffect } from "react";
import Weeks from "./components/Weeks/Weeks";

function Planner({ expand, data, module, setExpand }) {

  const plannerClass = expand ? 'lesson__sidebar__planner expanded' : 'lesson__sidebar__planner';

  const [activeSections, setActiveSection] = useState({});

  useEffect(() => {
    const sectionStatuses = data.lessons.reduce((acc, curr) => {
      const status = module.section === curr.slug;
      return { ...acc, [curr.slug]: status };
    }, {})

    setActiveSection(sectionStatuses);
  }, [data.lessons, module.section])

  return (
    <div className={plannerClass}>
      {data.lessons.map((lesson, i) => {
        return (
          <Weeks
            key={"weeks" + i}
            lesson={lesson}
            parentModule={module}
            lesson_title={lesson.name}
            status={lesson.completed}
            week={lesson.label}
            slug={lesson.slug}
            date_available={lesson.date}
            is_active={lesson.active}
            locked={lesson.locked}
            modules={lesson.modules}
            activeSections={activeSections}
            setActiveSections={setActiveSection}
            setExpand={setExpand}
          />
        );
      })}
    </div>
  );
}

export default Planner;
