import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import coreService from "../../../../services/core.service";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AcademiesList from "./AcademiesList/AcademiesList";



const title = {
  library: 'Resume Learning',
  completed: 'My completed courses'
}

function MyCourses() {
  /*
    academy_collection
      >next_module
      >webinar_session
  */
  const [academiesData, setAcademiesData] = useState([]);
  const [discoverMoreAcademiesData, setDiscoverMoreAcademiesData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('library');
  const [isLoading, setIsLoading] = useState(0);
  useEffect(() => {
    setIsLoading((prevLoading) => prevLoading + 2)
    coreService
      .getAcademies()
      .then((res) => setAcademiesData(res.data.records))
      .catch((err) => {console.log(err)})
      .finally(() => setIsLoading((prevLoading) => prevLoading - 1));

    coreService
      .getDiscoverMoreAcademies()
      .then((res) => setDiscoverMoreAcademiesData(res.data.records))
      .catch((err) => {console.log(err)})
      .finally(() => setIsLoading((prevLoading) => prevLoading - 1));
  }, []);

  useEffect(() => {
    setDiscoverMoreAcademiesData((prev) => prev.filter((item) => !academiesData.find((academy) => academy.slug === item.academy.slug)));
  }, [academiesData])

  const { t } = useTranslation();
  const completedAcademies =  academiesData.filter((academy) => academy.completed)
  const libraryAcademies = academiesData.filter((academy) => !academy.completed)
  return (
    <div className="dashboard__container__my_courses__container">
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <h1 className="academies-list-name">{title[selectedTab]}</h1>
        <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab className="courses-tab" value="library" label={t("Library")} />
            <Tab className="courses-tab" value="completed" label={t("Completed")} />
          </Tabs>
        </div>
      </div>
      {selectedTab === 'library' && (
        <div>
          {!libraryAcademies.length && (isLoading > 0 ? t('Loading...') : t('No courses found'))}
          {libraryAcademies.length > 0 && <AcademiesList list={libraryAcademies} />}
        </div>
      )}
      {selectedTab === 'completed' && (
        <div>
          {!completedAcademies.length && (isLoading > 0 ? t('Loading...') : t('No courses found'))}
          {completedAcademies.length > 0 && <AcademiesList list={completedAcademies} />}
        </div>
      )}
      {discoverMoreAcademiesData.length > 0 && <h1 className="discover-more">Discover More</h1>}
      {discoverMoreAcademiesData.length > 0 && <AcademiesList isDiscover list={discoverMoreAcademiesData} />}
    </div>
  );
}

export default MyCourses;
