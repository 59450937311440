import Logo from "../../assets/images/logo.svg";
function Footer() {
  return (
    <footer className="footer">
        <img src={Logo} alt="company logo" />
        <span className="year">{new Date().getFullYear()}</span>
    </footer>
  )
}

export default Footer;