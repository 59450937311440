import React from 'react'
import { Navigate } from 'react-router-dom';
import userService from "../../services/user.service";

const ProtectedView = ({ children }) => {
  const user = userService.getUser();

  if (!user) return <Navigate to="/" />

  if (user && user.active) return children;

  return <Navigate replace to="/inactive" />
}

export default ProtectedView;
