import { useCallback, useRef, useState, useEffect } from "react";
import TileContainer from "../AcademyCard/TileContainer/TileContainer";
import DiscoverMoreCard from "./DiscoverMoreCard/DiscoverMoreCard";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

function AcademiesList({ list, isDiscover }) {
  const buffer = 100
  const [itemWidth, setItemWidth] = useState(0)
  const listRef = useRef(null);
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  const scroll = useCallback((forward) => {
    listRef.current.scrollBy({ left: forward ? (itemWidth + buffer) : -(itemWidth + buffer), behavior: 'smooth' });
    setTimeout(() => {
      setHasPrevious(listRef.current.scrollLeft > 0)
      setHasNext(Array.from(document.querySelectorAll('.academy-item')).pop().getBoundingClientRect().x - listRef.current.scrollLeft > itemWidth)
    }, 100)
  }, [listRef, itemWidth])

  useEffect(() => {
    const containerWidth = document.querySelector('.academies-list').getBoundingClientRect().width
    const newItemWidth = document.querySelector('.academy-item').getBoundingClientRect().width
    setItemWidth(newItemWidth)
    if (newItemWidth * list.length > containerWidth) {
      setHasNext(true)
    }
  }, [list])
  return (
    <div className={`academies-list-container ${hasPrevious ? 'show-prev' : ''} ${hasNext ? 'show-next' : ''}`}>
      <div className="academies-list" ref={listRef}>
        {list.map((data, i) => (
          <div className="academy-item" key={i}>
            {isDiscover ? <DiscoverMoreCard discoverMoreAcademy={data} /> : <TileContainer academy={data} />}
          </div>
        ))}
      </div>
      {hasPrevious && <KeyboardArrowLeft className="prev" onClick={() => scroll()} />}
      {hasNext && <KeyboardArrowRight className="next" onClick={() => scroll(true)} />}
    </div>
  )
}

export default AcademiesList