import React from 'react'
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import userService from '../../services/user.service';
import ProtectedView from '../../components/ProtectedView';

const Home = () => {
  const user = userService.getUser();

  if (user) return <ProtectedView><Dashboard /></ProtectedView>;

  return <Login />
}

export default Home;
