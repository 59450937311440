import React, {useEffect, useState} from "react";

function VideoChat({module, videoEnded, video_url}) {
    const [data, setData] = useState(module);

    useEffect(() => {
        setData(module);
    }, [module]);

   
    return (
        <div className="video-chat">
            {!data.locked && video_url && !videoEnded ? (
                <iframe src={video_url.replace(/\/+$/, '') + '/chat/'} height="100%" width="100%" frameBorder="0"></iframe>
            ) : null }
        </div>
    );
    
}

export default VideoChat;
