import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyCourses from "./components/MyCourses/MyCourses";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import coreService from "../../services/core.service";
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import AcademyHeroImage from "../../assets/images/academy-hero.jpg";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false)
  const [heroAcademy, setHeroAcademy] = useState({})
  useEffect(() => {
    setIsLoading(true)
    coreService
      .getHeroAcademy()
      .then((res) => { setHeroAcademy(res.data)})
      .catch((err) => {console.log(err)})
      .finally(() => setIsLoading(false));
  }, [])
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <main className="dashboard">

      <Header dashboard={true} calendar={false} showSkipToContent={true} />
      <div className="dashboard__container">
        <div className="dashboard__container_main">
          {isLoading && <div className="dashboard__container__main_loading">{t('Loading...')}</div>}
          {!heroAcademy && !isLoading && (
            <div className="dashboard__container_main__fallback_hero" style={{ backgroundImage: `url(${AcademyHeroImage}`}} />
          )}
          {heroAcademy && !isLoading && (
            <div
              className="dashboard__container_main__banner"
              style={{ backgroundImage: `url(${heroAcademy.background ?? AcademyHeroImage})`}}
            >
                <div className="dashboard__container_main__banner-gradient" />
                <div className="dashboard__container_main__banner-content">
                  <h1 className="name">{heroAcademy.name}</h1>
                  <p className="stats">
                    <span className="lesson_count">{heroAcademy.lessons_count}</span>&nbsp;{heroAcademy.lessons_count === 1 ? t('lesson') : t('lessons')} <span className="divider"></span> <span className="percentage">{heroAcademy.completion_rate}%</span> {t('complete')}
                  </p>
                  <p className="description">
                    {heroAcademy.description}
                  </p>
                  <div>
                    <button className="continue_learning_btn" onClick={() => {
                      const url = `/academies/${heroAcademy.slug}/?module=${heroAcademy.next_module.slug}`;
                      navigate(url);
                    }}>
                      {t('Continue Learning')}
                    </button>
                  </div>
                  {heroAcademy.next_module && (
                    <p className="next-module-wrapper">
                      <SmartDisplayOutlinedIcon className="play_icon" />
                      {t('Next Lesson: ')} {heroAcademy.next_module.name}
                    </p>
                  )}
                </div>
            </div>
          )}

          <div id="main-content" className="dashboard__container_main__my_courses">
            <MyCourses />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Dashboard;
