import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import Planner from "./Planner/Planner";

function Sidebar({ data, module }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setExpand(true);
    }
  }, []);

  return (
    <div className="lesson__sidebar">
      <Header expand={expand} set_expand={setExpand} data={data} />
      <Planner expand={expand} setExpand={setExpand} data={data} module={module}/>
    </div>
  );
}

export default Sidebar;
