import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useTranslation } from "react-i18next";

const TileContent = ({ completed,
  tile_title,
  tile_header,
  style,
  slug,
  academySlug,
  certificate,
  onClick
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="tile_container__tile"
      style={style}
      onClick={() => {
          onClick(academySlug, slug);
      }}
    >
      {completed && (
        <div className='ribbon-icon-wrapper'>
          <WorkspacePremiumOutlinedIcon className='ribbon-icon' />
        </div>
      )}
      <div className="tile-top">
        <div className='tile_header_wrapper'>
          {!completed && <MenuBookIcon className="tile_icon" />}
          <h4 className="tile_header">{tile_header}</h4>
        </div>
        <h3 className="tile_title">{tile_title}</h3>
      </div>
      <div className="info_wrapper">
        <div className="text_wrapper">
        </div>
        <div className="button_wrapper">
          {completed ? (
            <>
              <span className='completed-text'>{t('Watch again')}</span>
              <button className='again-icon-wrapper'>
                <ArrowForwardOutlinedIcon className='again-icon' />
              </button>
            </>
          ): (
            <button>
              <PlayArrowIcon className='play-icon' />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default TileContent;
