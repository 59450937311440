import React from "react";
import { useNavigate } from "react-router-dom";
import { BlobProvider } from '@react-pdf/renderer';
import TileContent from './TileContent';
import Certificate from "../../../../../../components/Certificate/Certificate";
import userService from "../../../../../../services/user.service";

function Tile(props) {

  let navigate = useNavigate();
  const { certificate, academy } = props;

  function openModule(academySlug, moduleSlug) {
    const url = `/academies/${academySlug}/?module=${moduleSlug}`;
    navigate(url);
  }

  const user = userService.getUser()

  if (certificate) {
    return <BlobProvider document={<Certificate first_name={user.first_name} last_name={user.last_name} academy={academy} />}>
      {({ blob, url, loading, error }) => {
        const onCLick = () => window.open(url, '_newtab');
        return <TileContent {...props} onClick={onCLick} />
      }}
    </BlobProvider>
  }

  return (
    <TileContent {...props} onClick={openModule} />
  );
}

export default Tile;
