import React from "react";
import { useTranslation } from "react-i18next";
import KeyboardTab from "@mui/icons-material/KeyboardTab";

const SkipToContent = () => {
  const { t } = useTranslation();

  const handleClickOnSelectSkipToContent = (event) => {
    event.preventDefault();

    const container = document.getElementById('main-content');
    if (container) {
        container.tabIndex = -1;
        container.focus();
        setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  };

  const handleKeyboardOnSelectSkipToContent = (event) => {
    if (event.key === 'Enter') {
      
      event.preventDefault();

      const container = document.getElementById('main-content');
      if (container) {
          container.tabIndex = -1;
          container.focus();
          setTimeout(() => container.removeAttribute("tabindex"), 1000);
      }
    }
  };

  return (
    <div  className="skip-to-content__wrapper">
      <span className="skip-to-content__icon">
        <KeyboardTab />
      </span>
      <button
        tabIndex="0"
        onClick={handleClickOnSelectSkipToContent}
        onKeyDown={handleKeyboardOnSelectSkipToContent}
        className="skip-to-content__button skip-to-content__text">
          {t('Skip to content')}
        </button>
    </div>
  );
};

export default SkipToContent;
