import React from "react";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../../UserProfile/UserProfile";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";

function MobileNav({ nav_toggle, set_nav_toggle }) {
  const { t } = useTranslation();
  let navigate = useNavigate()

  const navigateTo = (link) => {
    navigate(link)
    set_nav_toggle(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const handleEditNavigation = () => {
    window.location.href = "https://office2.nvisionu.com/app.html#/TeamDashboard"
  }


  return (
    <nav
      className="header__mobile_nav"
      style={nav_toggle ? { height: "10rem" } : { height: "0" }}
    >
      <li className="links" onClick={() => navigateTo('/')}>{t("Dashboard")}</li>
    {
    /*
    <li className="links" onClick={() => navigateTo('/calendar')}>Webinars</li>
    */
    }

      <div className="logout_wrapper">
        <UserProfile
          isThereName={true}
          height="40px"
          width="40px"
          fontSize="0.875rem"
        />
        <button className="logout_btn" onClick={handleLogout}>
          <span>{t("Logout")}</span>
          <LogoutIcon style={{ fontSize: "1rem" }} />
        </button>
        <button className="edit_acc_btn" onClick={handleEditNavigation}>
            {t('Edit Account')}
            <EditIcon style={{ fontSize: "1rem" }} />
          </button>
      </div>
    </nav>
  );
}

export default MobileNav;
