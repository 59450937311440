import React from 'react';
import { Document, View, Page, Image, Text, StyleSheet } from '@react-pdf/renderer';

const Certificate = ({first_name, last_name, academy}) => {
    const styles = StyleSheet.create({
      nameTitleStyles: {
        marginTop: '16px',
        fontSize: '32px',
        fontWeight: '700',
        display: 'block'
      },
      spanStyles: {
        marginTop: '16px',
        fontSize: '24px',
        fontWeight: '500',
        display: 'block'
      },
      certStyles: {
        textAlign: 'center',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        backgroundImage: 'url(background-wave.png.png), url(logo.png)',
        backgroundPosition: 'right bottom, left top',
      },
      backgroundWave: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },
      LogoStyles: {
        marginBottom: '32px',
        position: 'absolute',
        top: '50px',
        left: '50px'
      },
    });

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View fixed>
          <Image src={academy.certificate_background} alt="" style={styles.backgroundWave}/>
        </View>
        <View style={styles.certStyles}>
          <Text style={styles.nameTitleStyles}>{`${first_name} ${last_name}`}</Text>
          <Text style={styles.spanStyles}>Congratulations! You have completed {`${academy.name} Academy`}.</Text>
        </View>
      </Page>
    </Document>
  )
}

export default Certificate
