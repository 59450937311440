import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ContainerRight() {
  let navigate = useNavigate();

  return (
    <div className="login_page__container_right">
      <h1 style={{ marginBottom: "5rem" }}>Access Denied</h1>
    </div>
  );
}

export default ContainerRight;
