import React, { useState } from "react";
import Header from "../../components/Header/Header";
import CompletedCertificateIcon from "../../assets/images/completed-certificate.svg";
import { useTranslation } from "react-i18next";

function CompleteCertificate() {
  const { t } = useTranslation();
  const [path, setPath] = useState({
    dashboard: true,
    webinars: false,
    calendar: false,
    inactive: false,
    courseComplete: false,
  });

  const downloadCertificateButton = {
    background: "linear-gradient(90deg, #262261 0%, #6BCAD2 100%)",
    border: "none",
    width: "300px",
    height: "64px",
    alignText: "center",
    borderRadius: "100px",
    color: "#fff",
    fontWeight: "500",
    fontSize: "18px",
  };
  const certificateContainerStyle = {
    height: "calc(100vh - 90px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const certificateCardStyle = {
    width: "780px",
    height: "480px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "0 80px",
    position: "relative",
  };

  const inactiveIconStyle = {
    height: "130px",
    width: "130px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    position: "absolute",
    top: "-65px",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "5px",
  };

  const inactiveIcon = {
    backgroundColor: "#6BCAD260",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="complete_certificate">
      <Header path={path} setPath={setPath} />

      <div className="certificate_container" style={certificateContainerStyle}>
        <div className="certificate_card" style={certificateCardStyle}>
          <div className="inactive_icon_container" style={inactiveIconStyle}>
            <div className="inactive_icon" style={inactiveIcon}>
              <img src={CompletedCertificateIcon} alt="" />
            </div>
          </div>
          <span
            style={{
              fontSize: "28px",
              lineHeight: "35px",
              fontWeight: "500",
              color: "#21273c",
            }}
          >
            {t("End of course certificate")}
          </span>
          <p
            style={{
              fontWeight: "400",
              fontSize: "20px",
              margin: "1rem 0 2rem 0",
              opacity: "0.7",
              color: "#21273D",
              lineHeight: "34px",
            }}
          >
            {t("inactive-subscription")}
          </p>
          <button style={downloadCertificateButton}>
            {t("Download Certificate")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompleteCertificate;
