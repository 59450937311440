import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function Header({ expand, set_expand, data }) {
  const [button, setButton] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setButton(false);
    }
  }, []);

  return (
    <div className="lesson__sidebar__header">
      <div className="text_wrapper">
        <h3>{data.name}</h3>
        <h6>{data.completion_rate}%</h6>
      </div>
      <div className="progress_bar-container">
        <div
          className="progress_bar"
          style={{ width: `${data.completion_rate}%` }}
        ></div>
      </div>
      {button ? (
        <button onClick={() => set_expand(!expand)}>
          {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
      ) : null}
      <hr />
    </div>
  );
}

export default Header;
