import axiosClient from "../helper/axios";
import { endpoints } from "./endpoints";

class UserService {
  /* Gets users data from local storage */
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  /* Returns a sso url for the user to login */
  login(email) {
    const url = `/${endpoints.sso_url.replace(
      "{email}",
      email
    )}`;
    return axiosClient.get(url);
  }

  /* Returns the current user information */
  manage() {
    const url = `/${endpoints.manage}`;
    return axiosClient.get(url);
  }

  /* Returns a jwt token when a user posts a valid token */
  retrieveToken(payload) {
    const url = `/${endpoints.access_token}`;
    return axiosClient.post(url, payload);
  }

  /* Refreshes the access token when a valid refresh has been given */
  refreshToken(payload) {
    const url = `/${endpoints.refresh}`;
    return axiosClient.post(url, payload);
  }
}

const userService = new UserService();
export default userService;
