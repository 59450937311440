import React, { useCallback, useEffect, useState } from "react";
import FileIcon from "../../../assets/images/file.svg";
import DownloadIcon from "../../../assets/images/download-icon.svg";
import { Done } from "@mui/icons-material";
import coreService from "../../../services/core.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import userService from "../../../services/user.service";
import PlayIcon from "@mui/icons-material/PlayArrow";
import LockIcon from '@mui/icons-material/Lock';
import { CircularProgress } from "@mui/material";

const NextUp = ({ data, video_url, video_ended, handleNextUpAction, nextModuleThumbnail, showLockError }) => {
  const { t } = useTranslation();
  return <div className="next-up-container">
    <div className="text_wrapper">
      <span className="module_resources">{t("Next Up")}</span>
      <p>
        {data.next_module_name}
      </p>
    </div>
    <div className="next-up-thumbnail-container" onClick={handleNextUpAction}>
      {(!data.completed && (video_url && !video_ended)) ? <LockIcon className="lockIcon" /> : <PlayIcon className="playIcon" />}
      {nextModuleThumbnail === "loading" && <CircularProgress />}
      {(nextModuleThumbnail && nextModuleThumbnail) !== "loading" && <img style={nextModuleThumbnail ? (!video_url || video_ended) ? null : { opacity: "0.5" } : null} src={nextModuleThumbnail} alt="next_module_thumbnail" />}
    </div>
    <div className="please-finish-video-error-message" style={showLockError ? { opacity: "100%" } : {}}>
      <p>{t("Please finish the current video before progressing")}</p>
    </div>
  </div>
}


function ModuleInformation({ module, video_ended, set_video_ended, video_started, set_video_started, video_url }) {
  let navigate = useNavigate();
  const { slug } = useParams();
  const user = userService.getUser();
  const [showLockError, setShowLockError] = useState(false);
  const [data, setData] = useState(module);
  const { t } = useTranslation();
  const [nextModuleThumbnail, setNextModuleThumbnail] = useState("loading")

  useEffect(() => {
    setNextModuleThumbnail("loading")
    setData(module);
    set_video_ended(false)
  }, [module]);

  useEffect(() => {
    setShowLockError(!data.completed && !video_ended)
  }, [video_ended, data.completed])

  useEffect(() => {
    setShowLockError(false)
  }, [data.completed])

  useEffect(() => {
    if (data.next_module_video_url) {
      coreService
        .getModuleVideoThumbnail(data.next_module_video_url.split("/").pop())
        .then((res) => {
          setNextModuleThumbnail(res.data)
        }).catch(e => {
          setNextModuleThumbnail(undefined)
        })
    }
    else
      setNextModuleThumbnail(undefined)
  }, [data])

  function updateModule(e) {
    const target = e.target;
    target.innerHTML = "Saving...";

    if (data.type === 'webinar' && data.sessions.length) {
      coreService.markWebinarSessionAsComplete(data.sessions[0].slug);
    }
    coreService
      .markModuleAsComplete(data.slug)
      .then((res) => {
        if (res.data.next_module_slug) {
          navigate(
            `/academies/${res.data.academy}?module=${res.data.next_module_slug}`
          );
        } else {
          // if possible navigate to next lesson
          navigate(
            `/academies/${res.data.academy}?module=${res.data.slug}`
          )
        }
        setData(res.data);
        target.innerHTML = t("Completed");
      })
      .catch((err) => {
        target.innerHTML = t("Complete");
      });
    set_video_ended(false)
    set_video_started(false)
  }

  function backToDashboard(e) {
    navigate(`/`);
  }

  const handleNextUpAction = useCallback((e) => {
    if (video_url && !video_ended)
      setShowLockError(true)
    else {
      updateModule(e)
    }
  }, [video_ended, video_url])

  const CompleteCard = () => <div className={"completion-container"}>
    <div className="card">
      <div className="text_wrapper">
        <span className="done_circle">
          <Done style={{ fontSize: '1rem' }} />
        </span>
        <span className="module_resources">
          {t("Module complete")}
        </span>
        <p style={{ marginBottom: (data.next_module_name && nextModuleThumbnail) ? "30px" : 0 }}>{t("You can proceed to the next module.")}</p>
        {(data.next_module_name && nextModuleThumbnail) &&
          <NextUp data={data} video_url={video_url} video_ended={video_ended} handleNextUpAction={() => { }} nextModuleThumbnail={nextModuleThumbnail} showLockError={showLockError} />}
      </div>
    </div>{" "}
  </div>

  return (
    <div className="lesson-container">
      {
        !data.locked ? (
          <div>
            <div className={"lesson__grid_wrapper__module_information"}>
              <div className="title_wrapper">
                {(!data.completed && data.type === 'webinar' && data.sessions.length > 0 && data.sessions[0].date) &&
                  <div className="module_main_webinar_status">
                    <div className="next-session">
                      <strong>{t("next-session")}:</strong> <Moment local locale={user.language}>{data.sessions[0].date}</Moment>
                    </div>
                    <div className="live-status">
                      <svg height="20" width="20" className={video_started ? "blinking" : null}>
                        <circle cx="10" cy="10" r="10" fill="transparent" />
                      </svg>
                      <span>{video_started ? t("live-now") : t("offline")}</span>
                    </div>
                  </div>
                }
                <h2 className="module_main_title">{data.name}</h2>
                <span className="module_main_sub_title">{data.subtitle}</span>
              </div>
              <div
                id={"content"}
                dangerouslySetInnerHTML={{ __html: data.content }}
              />

              {data.h5p_url &&
                <>
                  <br />
                  <iframe
                    src={`${data.h5p_url}/embed`}
                    aria-label="Test quiz"
                    width="900"
                    height="400"
                    frameBorder="0"
                    allowFullScreen="allowfullscreen"
                    allow="autoplay *; geolocation *; microphone *; camera *; midi *; encrypted-media *"
                  />
                  <script src="https://nvisionu.h5p.com/js/h5p-resizer.js" charSet="UTF-8" />
                </>}

              {
                data.files.length > 0 ? (
                  <div className="resources">
                    {data.files.map((file, index) => {
                      return (
                        <React.Fragment key={"key" + index}>
                          <span className="module_resources" >{t("Resources")}</span>
                          <a

                            className="resources__card"
                            href={file.file_url}
                            target={"_blank"}
                          >
                            <div className="file">
                              <img src={FileIcon} alt="" />
                            </div>
                            <div className="text_wrapper">
                              <span className="module_resources_filename">
                                {file.name}
                              </span>
                              <p>{file.subtitle}</p>
                            </div>
                            <button>
                              <img src={DownloadIcon} alt="" />
                            </button>
                          </a>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
            </div>

            {!data.completed ? (
              <div className="completion-container">
                <div className="card">
                  {(!data.next_module_name || !nextModuleThumbnail) && <>
                    <div className="text_wrapper">
                      <span className="module_resources">{t("Mark as completed")}</span>
                      <p>
                        {t("Once you're done with this module, you can mark it as completed")}
                      </p>
                    </div>
                    <button
                      style={video_url ? video_ended ? null : { opacity: "0.5" } : null}
                      disabled={video_url ? video_ended ? false : true : false}
                      onClick={(e) => {
                        updateModule(e);
                      }}
                    >
                      {t("Complete")}
                    </button>
                  </>}
                  {(data.next_module_name && nextModuleThumbnail) &&
                    <NextUp data={data} video_url={video_url} video_ended={video_ended} handleNextUpAction={handleNextUpAction} nextModuleThumbnail={nextModuleThumbnail} showLockError={showLockError} />}
                </div>
              </div>
            ) : (
              data?.next_module_slug ? <Link to={"/academies/" + slug + "/?module=" + data.next_module_slug}>
                <CompleteCard/>
              </Link> : <CompleteCard/>
            )}
          </div>
        ) : null}

      {data.locked ? (
        <div className="completion-container">
          <div className="card">
            <div className="text_wrapper">
              <span className="module_resources">
                {t("no-permission-module-complete")}
              </span>
              <p>
                {t("complete-module")}
              </p>
            </div>
            <button
              onClick={(e) => {
                backToDashboard(e);
              }}
            >
              {t("Back to dashboard")}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ModuleInformation;
