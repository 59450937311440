import React from "react";
import HeroImage from "../../../assets/images/login-hero.webp";
function ContainerLeft() {
  return (
    <div className="login_page__container_left">
      <div className="login_page__container_left__overlay"></div>
      <img src={HeroImage} alt="" />
      {
        /*
        <div className="terms_and_conditions">
          <span className="terms_and_condtions-text">
            Terms & Conditions <span className="bullet">Privacy</span>
          </span>
        </div>
        */
      }
    </div>
  );
}

export default ContainerLeft;
