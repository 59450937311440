import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { GApageView } from '../../analytics';

const usePageView = (title) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title;
    GApageView();
  }, [location, title])
}

export default usePageView;
