import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import { Oval } from "react-loader-spinner";
import BackgroundImage from '../../assets/images/login-hero.png'

function LoginLoading({set_user}) {
    /* Retrieve token from query parameter and get a jwt token from the api */
    let navigate = useNavigate();

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const payload = {
        token: token,
    };

    const successfulNav = () => {
        navigate("/");
    };

    // const unsuccessfulNav = () => {
    //     set_success(false)
    //     navigate('/')
    // }

    userService
        .retrieveToken(payload)
        .then((res) => {
            const data = res.data;
            set_user(data);
            localStorage.setItem("user", JSON.stringify(data));
            successfulNav();
        })
        .catch((err) => {
          console.log(err)
        });

  return (
    <div className="login_page">

      <img src={BackgroundImage} alt="" className="hero_image"/>
      <div className="overlay"></div>
      <div className="login_page__container_left">
        {
          /*
        <div className="terms_and_conditions">
          <span className="terms_and_condtions-text">
            Terms & Conditions <span className="bullet">Privacy</span>
          </span>
        </div>
        */
        }
      </div>

      <div className="login_page__container_right">
        <h1 style={{ marginBottom: "5rem" }}>Logging in</h1>
        <div className="oval_container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Oval
              height={80}
              width={80}
              color={window.innerWidth < 1080 ? "#fff" : "#21273c"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#bbbdbf"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
        </div>
      </div>

    </div>
  );
}

export default LoginLoading;
