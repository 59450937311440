import React, {useState, useEffect} from "react";
import ModuleInformation from "./ModuleInformation/ModuleInformation";
import Sidebar from "./Sidebar/Sidebar";
import ModuleHeader from "./ModuleHeader/ModuleHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import coreService from "../../services/core.service";
import Header from "../../components/Header/Header.js";
import { GApageView } from "../../analytics";

function Academy() {
    const [data, setData] = useState({
        slug: "",
        lessons: [],
    });
    const [module, setModule] = useState({
        files: [],
    });

    const [videoStarted, setVideoStarted] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const { slug } = useParams();
    const videoURL = module.type === 'webinar' && module.sessions.length > 0 ? module.sessions[0].live_url : module.video_url;
    
    useEffect(() => {
      if (module && module.name) {
        document.title = `NVisionU - ${module.name}`;
        GApageView();
      }
    }, [module])

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }, [location])

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      let moduleSlug = queryParams.get("module");
      coreService.getAcademyOverview(slug, {}, {}).then((response) => {
          setData(response.data);
          const tempData = response.data;
          if (!moduleSlug) {
              if (tempData.lessons.length > 0) {
                  const module = tempData.lessons[0].modules[0];
                  moduleSlug = module.slug;
              }
          }
          if (moduleSlug) {
              coreService.getModule(moduleSlug, {}, {}).then((response) => {
                  setModule(response.data);
              }).catch(err => {
                if (err.response.status === 403 || err.response.status === 404) {
                  setModule({ slug: 'locked', locked: true });
                }
              });
          }
      }).catch(e => e.response.status === 403 ? navigate('/') : null);
  }, [location]);

  return (
    <div className="academy">

      <Header showSkipToContent={!!module.type} />
      <div className="academy__container">
        <Sidebar data={data} module={module}/>
        <div id="main-content" tabIndex="0" className="academy__grid_wrapper">
          {
            module.slug && !module.locked ?
              (
                <ModuleHeader module={module} set_video_ended={setVideoEnded} video_ended={videoEnded} set_video_started={setVideoStarted} video_url={videoURL}/>
              ) : null
          }
        
          {
            module.slug ?
            (
              //Always allow webinars to be marked complete
              <ModuleInformation module={module} video_ended={module.type === 'webinar' ? true : videoEnded} set_video_ended={setVideoEnded} video_started={videoStarted} set_video_started={setVideoStarted} video_url={videoURL}/>
            )
            : null
          }
        </div>
      </div>
    </div>
  )
}

export default Academy;
