import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MobileNav from "./components/MobileNav/MobileNav";
import DesktopNav from "./components/DesktopNav/DesktopNav";
import SkipToContent from "../SkipToContent/SkipToContent";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Header({ dashboard, calendar, showSkipToContent }) {
  const [navToggle, setNavToggle] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate()
  const location = useLocation();

  return (
    <header className="header">
     {showSkipToContent && <SkipToContent />}
      <button className="back_btn" onClick={() => location.pathname === '/' ? window.location.href = 'https://office2.nvisionu.com/app.html#/Home'  : navigate('/')}>
        <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
        <span>{t(location.pathname.includes('/academies') ? 'Back to LMS' : 'Go to back office')}</span>
      </button>
      <img src={Logo} alt="company logo" onClick={() => navigate('/')} style={ {cursor: "pointer"} } />
      <button
        className="header__nav_toggle"
        onClick={() => setNavToggle(!navToggle)}
      >
        {navToggle ? (
          <CloseIcon style={{ color: "#21273c" }} />
        ) : (
          <MenuIcon style={{ color: "#21273c" }} />
        )}
      </button>
      <MobileNav nav_toggle={navToggle} set_nav_toggle={ setNavToggle }/>
      <DesktopNav dashboard={dashboard} calendar={calendar} />
    </header>
  );
}

export default Header;
