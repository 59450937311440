import React from "react";
import BackgroundImage from '../../assets/images/login-hero.png'

function Login() {

  function login(e) {
    e.preventDefault();
    window.location.href = "https://office2.nvisionu.com/index.html#/Login"
  }

  return (
    <div className="login_page">

      <img src={BackgroundImage} alt="" className="hero_image"/>
      <div className="overlay"></div>
      <div className="login_page__container_left">
        <div className="login_page__container_left__overlay"></div>
        {
          /*
        <div className="terms_and_conditions">
          <span className="terms_and_condtions-text">
            Terms & Conditions <span className="bullet">Privacy</span>
          </span>
        </div>
        */
        }
      </div>

      <div className="login_page__container_right">
        <h1>Please login to access the Digital Learning Platform.</h1>
        <form>
          {/* <label htmlFor="">Email</label>
          <input type="email" onChange={(e) => setEmail(e.target.value)} />
          <label style={noUserStyle}>Incorrect Email!</label> */}
          <div className="login_button_wrapper">
            <button onClick={(e) => login(e)}>
              <h2>Login</h2>
            </button>
          </div>
        </form>
      </div>

    </div>
  );
}

export default Login;
