import React from 'react'
import { useNavigate } from 'react-router-dom';
import coreService from '../../../../../../services/core.service';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StatusCheck from '../StatusCheck/StatusCheck';

function ModulesAccordion({slug, lesson_title, modules_array, set_modules_array, setActiveSections, activeSections, current_module, lesson, setExpand}) {
    let navigate = useNavigate()

    function openModule(module) {
        navigate(`/academies/${module.academy}/?module=${module.slug}`);
        setExpand(false);
    }

    function handleSectionClick() {
      const params = { section: slug, limit: 100 };
      if (modules_array.length === 0) {
          coreService.getModules({}, params).then((res) => {
              set_modules_array(res.data.records);
              setActiveSections({ ...activeSections, [slug]: !activeSections[slug] });
          });
      } else {
          setActiveSections({ ...activeSections, [slug]: !activeSections[slug] });
      }
    }

  return (
    <div className="accordion">
        <div className="accordion_header" onClick={() => handleSectionClick()}>
        <h4>{lesson_title}</h4>
            {activeSections[slug] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        <ul
        className={activeSections[slug] ? "accordion-active" : undefined}
        >

        {
            modules_array.map((module, i) => {

                // if previous module is not completed then the current module will be locked
                let isLocked
                function checkPrevModuleStatus() {
                    // is current module first then return locked false
                    // is current module not first then check if previous module has been completed
                    // if previous module has been completed then return locked as false
                    // else return locked as true
                    if (lesson.locked || !lesson.active) {
                      isLocked = true;
                      return;
                    }
                    if(i === 0) {
                        isLocked = false
                    } else if(i === 1 && modules_array[i - 1].completed) {
                        isLocked = false
                    } else if(i > 1 && modules_array[i - 1].completed) {
                        isLocked = false
                    } else {
                        isLocked = true
                    }
                }
                let moduleClassName = ['module_title'];
                if(current_module && current_module.slug === module.slug){
                    moduleClassName.push('active')
                }
                
                if(module.type === 'webinar'){
                    moduleClassName.push('webinar')
                }
                checkPrevModuleStatus(isLocked)
                return(
                    <li key={"list" + i}
                        className={moduleClassName.join(' ')}
                    >
                        <button
                            aria-hidden={!activeSections[slug]}
                            style={{
                                display: "inline-flex",
                                background: "transparent",
                                padding: "0",
                                border: "none",
                                margin: "0",
                                color: "white",
                                justifyContent: "flex-start",
                                textAlign: "left",
                                gap: "0.5rem",
                                alignItems: "center",
                                fontSize: "0.9375rem",
                            }}
                            onClick={() => isLocked ? null : openModule(module)}
                        >
                        <div
                            className="check_container"
                            style={{
                            height: "1.875rem",
                            width: "1.875rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: "0",
                            }}
                        >
                            <StatusCheck
                                status = { module.completed }
                                locked = { isLocked }
                            />
                        </div>
                        {module.name}
                        </button>
                    </li>
                )
            })}
        </ul>
    </div>
  )
}

export default ModulesAccordion
