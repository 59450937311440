import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import coreService from "../../services/core.service";
import Header from "../../components/Header/Header";
import Filter from "./Filter/Filter";
import { useNavigate  } from "react-router-dom";
import listPlugin from '@fullcalendar/list';
import { useTranslation } from "react-i18next";

function CalendarPage() {

  // currentEventAcademy will be selected from the filter -- default as 'Academy' which will list all webinar events
  const [currentEventAcademy, setCurrentEventAcademy] = useState('Academy')

  // allWebinarEvents will store all events
  const [allWebinarEvents, setAllWebinarEvents] = useState([])

  // events will store the filtered academy events
  const [events, setEvents] = useState([])

  let navigate = useNavigate()

  useEffect(() => {
    // getting all next webinar events
    coreService.getAcademies().then(res => {
      const records = res.data.records
      const nextWebinars = []
      
      for(let i = 0; i < records.length; i++) {
        if(!records[i].completed) {
          const webinarSession = records[i].webinar_session
          const webinarDate = new Date(Date.parse(webinarSession.date))
          const webinarName = webinarSession.webinar.name
          const academyName = webinarSession.academy
          const academySlug = records[i].slug
          const moduleSlug = records[i].webinar_session.webinar.slug
          const webinar = {academy: academyName, title: webinarName, date: webinarDate, academySlug: academySlug, moduleSlug: moduleSlug }
          nextWebinars.push(webinar)
        }
      }
      setAllWebinarEvents(nextWebinars)
    })
  }, [])

  // setting filter academy events
  useEffect(() => {
    const filteredEvents = [];
    allWebinarEvents.filter((webinar) => {
      if (webinar.academy.toLowerCase() === currentEventAcademy.toLowerCase()) {
        filteredEvents.push(webinar);
      }
    });
    setEvents(filteredEvents);
  }, [currentEventAcademy]);

  const { t } = useTranslation();

  function openModule(academySlug, moduleSlug) {
    const url = `/academies/${academySlug}/?module=${moduleSlug}`;
    navigate(url);
  }
  const handleEventClick = (e) => {
    let academySlug = "";
    let moduleSlug = "";
    const eventTitle = e.event._def.title;
    allWebinarEvents.filter((event) => {
      if (event.title === eventTitle) {
        academySlug = event.academySlug;
        moduleSlug = event.moduleSlug;
      }
    });
    openModule(academySlug, moduleSlug);
  };

  return (
    <div className="calendar_page">
      <Header dashboard={false} calendar={true} />
      <h1>{t("Webinars")}</h1>

      <div className="calendar_page__container">

        <Filter
          // academies={academies}
          current_event_academy = { currentEventAcademy }
          set_current_event_academy={ setCurrentEventAcademy }
          all_webinar_events = {allWebinarEvents}
          set_events = {setEvents}
          events = {events}
        />

        <div className="month_view_calendar">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={currentEventAcademy === "Academy" ? allWebinarEvents : events}
            eventColor={"#6bcad2"}
            eventClick={ (e) => handleEventClick(e) }
            eventTimeFormat={{hour: 'numeric', minute: '2-digit'}}
          />
        </div>
        <div className="list_view_calendar">
          <FullCalendar
            plugins={[listPlugin]}
            initialView="listWeek"
            events={currentEventAcademy === "Academy" ? allWebinarEvents : events}
            eventColor={"#6bcad2"}
            eventClick={ (e) => handleEventClick(e) }
            eventTimeFormat={{hour: 'numeric', minute: '2-digit'}}
          />
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;
