import React from "react";
import userService from "../../services/user.service";

function UserProfile({ height, width, fontSize, marginRight }) {
  const initialsStyle = {
    height: height,
    width: width,
    fontSize: fontSize,
    marginRight: marginRight,
  };

  const user = userService.getUser();

  const userInitials = () => {
    const firstName = user.first_name;
    const lastName = user.last_name;

    const initials = `${firstName.split("")[0]} ${lastName.split("")[0]} `;

    return initials;
  };
  return (
    <div className="user_profile">
      <div className="user_profile__initials" style={initialsStyle}>
        {userInitials()}
      </div>
    </div>
  );
}

export default UserProfile;
