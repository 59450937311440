import React from "react";
import ContainerLeft from "./ContainerLeft/ContainerLeft";
import ContainerRight from "./ContainerRight/ContainerRight";

function LoginAccessDenied() {
  return (
    <div className="login_access_denied">
      <ContainerLeft />
      <ContainerRight />
    </div>
  );
}

export default LoginAccessDenied;
