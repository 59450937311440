import React from "react";
import TickIcon from "../../../../../../assets/images/tick.svg";
import LockIcon from '@mui/icons-material/Lock';
function StatusCheck({ status, locked }) {
  // status will determine what type of status circle should be rendered.
  // if status is completed then it will show a soft teal circle with a tick icon
  // if the status is currently doing, then it will show a transparent circle with soft teal border
  // if the status is locked then it will show a transparent circle with border white opacity .1

  const circleStyle = {
    height: status ? "1.625rem" : "1.125rem",
    width: status ? "1.625rem" : "1.125rem",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: status
      ? "#6bcad2"
      : status === "transparent"
      ? "red"
      : "transparent",
    border: status
      ? undefined
      : status === "currently"
      ? "1px solid #6bcad2"
      : "1px solid rgba(255, 255, 255, 0.5)",
  };

  const circleIncompleteStyle = {
    height: status ? "1.625rem" : "1.125rem",
    width: status ? "1.625rem" : "1.125rem",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "1px solid #6bcad2",
  };

  const lockedStyle = {
    border: 'none'
  }

  return (
    <div style={status && !locked ? circleStyle : !status && locked ? lockedStyle : circleIncompleteStyle}>
      <img
        src={TickIcon}
        alt=""
        style={status ? undefined : { display: "none" }}
      />
      <LockIcon
        style={ locked ? {fontSize: '0.75rem'} : { display: "none" } }
      />
    </div>
  );
}

export default StatusCheck;
