import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const logout = () => {
  localStorage.removeItem("user");
  window.location.href = "/";
}

const getUser = () => JSON.parse(localStorage.getItem("user"));

const requestAccessToken = async () => apiClient.post('/users/token/refresh/', { refresh: getUser().token.refresh });

const refreshAccessToken = async () => {
  const { data } = await requestAccessToken();
  const userData = getUser();
  userData.token.access = data.token.access;
  userData.token.refresh = data.token.refresh;
  localStorage.setItem("user", JSON.stringify(userData));
  const { access, refresh } = data.token;
  return { access, refresh };
}

apiClient.interceptors.request.use(
  async config => {
    const user = getUser();
    if (user) {
      config.headers = {
        'Authorization': `Bearer ${user.token.access}`,
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

apiClient.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config;
  try {
    const isTokenExpired = error.response.data.code === 'token_not_valid' && error.response.data.messages[0].token_type === 'access';
    if (error.response.status === 401 && !originalRequest._retry && isTokenExpired) {
      originalRequest._retry = true;
      const { access } = await refreshAccessToken();
      apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + access;
      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  } catch (err) {
    originalRequest._retry = false;
    logout();
    return Promise.reject(err);
  }
});

class AxiosClient {
  headers = {};
  baseUrl = process.env.REACT_APP_API_URL;
  intercepting = false;

  /* Injects a token into the request */
  _injectToken() {
    const user = this.getUser();
    this.headers.Authorization = `Bearer ${user.token.access}`;
  }

  /* Returns the user object from localstorage */
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  /* Checks to see if the user is logged in */
  isLoggedIn() {
    const user = this.getUser();
    return user && user.token !== undefined && user.token !== null;
  }

  /* Returns a boolean indicating if the access token as expired */
  isAccessTokenExpired() {
    if (!this.isLoggedIn()) {
      return false;
    }
    const user = this.getUser();
    const expiry = JSON.parse(atob(user.token.access.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  /* Returns a boolean indicating if the refresh token has expired */
  isRefreshTokenExpired() {
    if (!this.isLoggedIn()) {
      return false;
    }
    const user = this.getUser();
    const expiry = JSON.parse(atob(user.token.refresh.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  /* Logs out the user and redirects them to home */
  logout() {
    localStorage.removeItem("user");
    window.location.href = "/";
  }

  /* Handles authentication for the application */
  async intercept() {
    const user = this.getUser();

    /* If there is no user available then let the request pass */
    if (user === null || user === undefined) {
      return;
    }

    const accessExpired = this.isAccessTokenExpired();
    const refreshExpired = this.isRefreshTokenExpired();

    if (!accessExpired) {
      // Inject the new token into the request
      this._injectToken();
    }
    /* If the access token & refresh token are expired then logout the user */
    if (accessExpired && refreshExpired) {
      this.logout();
    }

    /* If the access token is expired and the refresh token is valid then refresh the token */
    if (accessExpired && !refreshExpired) {
      this.intercepting = true;
      try {
        const { data } = await this.requestAccessToken();
        const userData = this.getUser();
        userData.token.access = data.token.access;
        userData.token.refresh = data.token.refresh;
        localStorage.setItem("user", JSON.stringify(userData));
        this._injectToken();
      } catch (err) {
        console.log("Error refreshing token", err);
      }
    }
  }

  async get(url, headers = {}, params = {}) {
    /* If not in the middle of an refreshing an acces token then intercept the request */
    if (!this.intercepting) {
      this.intercept();
    }
    const axiosHeaders = { ...headers, ...this.headers };
    return axios.get(url, { headers: axiosHeaders, params: params });
  }

  async post(url, headers = {}, data = {}) {
    /* If not in the middle of an refreshing an acces token then intercept the request */
    if (!this.intercepting) {
      this.intercept();
    }
    const axiosHeaders = { ...headers, ...this.headers };
    return axios.post(url, data, { headers: axiosHeaders });
  }

  async patch(url, headers = {}, data = {}) {
    /* If not in the middle of an refreshing an acces token then intercept the request */
    if (!this.intercepting) {
      this.intercept();
    }
    const axiosHeaders = { ...headers, ...this.headers };
    return axios.patch(url, data, { headers: axiosHeaders });
  }

  async delete(url, headers = {}) {
    /* If not in the middle of an refreshing an acces token then intercept the request */
    if (!this.intercepting) {
      this.intercept();
    }
    const axiosHeaders = { ...headers, ...this.headers };
    return axios.delete(url, { headers: axiosHeaders });
  }

  /* Requests a new access token */
  async requestAccessToken() {
    const url = `${this.baseUrl}/users/token/refresh/`;
    const payload = {
      refresh: this.getUser().token.refresh,
    };
    return this.post(url, {}, payload);
  }
}

export default apiClient;
