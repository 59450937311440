import axiosClient from "../helper/axios";
import { endpoints } from "./endpoints";

class CoreService {
  baseUrl = process.env.REACT_APP_API_URL;

  /* Returns a list of collections */
  getCollections(headers = {}, params = {}) {
    const url = `/${endpoints.collections}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a collection */
  createCollection(headers = {}, data = {}) {
    const url = `/${endpoints.collections}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a collection */
  updateCollection(headers = {}, data = {}) {
    const url = `/${endpoints.collection}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a collection */
  deleteCollection(headers = {}, params = {}) {
    const url = `/${endpoints.collection}`;
    return axiosClient.delete(url, { params, headers });
  }

  /* Returns a list of academies */
  getAcademies(headers = {}, params = {}) {
    const url = `/${endpoints.academies}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Returns the academy to be dispalyed in the dashboard hero */
  getHeroAcademy(headers = {}, params = {}) {
    const url = `/${endpoints.academyHero}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Returns a list of academies for the discover more section */
  getDiscoverMoreAcademies(headers = {}, params = {}) {
    const url = `/${endpoints.academyDiscover}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a academy */
  createAcademy(headers = {}, data = {}) {
    const url = `/${endpoints.academies}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Retrieve a academy */
  getAcademy(headers = {}, params = {}) {
    const url = `/${endpoints.academy}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Retrieve an academy overview */
  getAcademyOverview(slug, headers = {}, params = {}) {
    const url = `/${endpoints.academyOverview.replace(
      ":slug",
      slug
    )}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Update a academy */
  updateAcademy(slug, headers = {}, data = {}) {
    const url = `/${endpoints.academy.replace(":slug", slug)}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a academy */
  deleteAcademy(headers = {}, params = {}) {
    const url = `/${endpoints.academy}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of sections */
  getSections(headers = {}, params = {}) {
    const url = `/${endpoints.sections}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a section */
  createSection(headers = {}, data = {}) {
    const url = `/${endpoints.sections}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a section */
  updateSection(headers = {}, data = {}) {
    const url = `/${endpoints.section}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a section */
  deleteSection(headers = {}, params = {}) {
    const url = `/${endpoints.section}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of modules */
  getModules(headers = {}, params = {}) {
    const url = `/${endpoints.modules}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Update a module */
  getModule(slug, headers = {}, params = {}) {
    const url = `/${endpoints.module.replace(":slug", slug)}`;
    return axiosClient.get(url, { headers, params });
  }
  /* Create a module */
  createModule(headers = {}, data = {}) {
    const url = `/${endpoints.modules}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a module */
  updateModule(headers = {}, data = {}) {
    const url = `/${endpoints.module}`;
    return axiosClient.patch(url, data, { headers });
  }

  markModuleAsComplete(slug, data = {}) {
    const url = `/${endpoints.moduleCompletion.replace(
      ":slug",
      slug
    )}`;
    return axiosClient.post(url, data);
  }
  /* Delete a module */
  deleteModule(headers = {}, params = {}) {
    const url = `/${endpoints.module}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of webinars */
  getWebinars(headers = {}, params = {}) {
    const url = `/${endpoints.webinars}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a webinar */
  createWebinar(headers = {}, data = {}) {
    const url = `/${endpoints.webinars}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a webinar */
  updateWebinar(headers = {}, data = {}) {
    const url = `/${endpoints.webinar}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a webinar */
  deleteWebinar(headers = {}, params = {}) {
    const url = `/${endpoints.webinar}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of webinar sessions */
  getWebinarSessions(headers = {}, params = {}) {
    const url = `/${endpoints.webinarSessions}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a webinar session */
  createWebinarSession(headers = {}, data = {}) {
    const url = `/${endpoints.webinarSessions}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a webinar session */
  updateWebinarSession(headers = {}, data = {}) {
    const url = `/${endpoints.webinarSession}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a webinar session */
  deleteWebinarSession(headers = {}, params = {}) {
    const url = `/${endpoints.webinarSession}`;
    return axiosClient.delete(url, { headers, params });
  }

  markWebinarSessionAsComplete(slug, data = {}) {
    const url = `/${endpoints.webinarSessionCompletion.replace(
      ":slug",
      slug
    )}`;
    return axiosClient.post(url, data);
  }

  getModuleVideoThumbnail(videoId, data = {}) {
    const url = `/${endpoints.moduleVideoThumbnail.replace(
      ":videoId",
      videoId
    )}`;
    return axiosClient.get(url, data);
  }


  /* Returns a list of quizzes */
  getQuizzes(headers = {}, params = {}) {
    const url = `/${endpoints.quizzes}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a quiz */
  createQuiz(headers = {}, data = {}) {
    const url = `/${endpoints.quizzes}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a quiz */
  updateQuiz(headers = {}, data = {}) {
    const url = `/${endpoints.quiz}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a quiz */
  deleteQuiz(headers = {}, params = {}) {
    const url = `/${endpoints.quiz}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of responses */
  getResponses(headers = {}, params = {}) {
    const url = `/${endpoints.responses}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a response */
  createResponse(headers = {}, data = {}) {
    const url = `/${endpoints.responses}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a response */
  updateResponse(headers = {}, data = {}) {
    const url = `/${endpoints.response}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a response */
  deleteResponse(headers = {}, params = {}) {
    const url = `/${endpoints.response}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of files */
  getFiles(headers = {}, params = {}) {
    const url = `/${endpoints.files}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a file */
  createFile(headers = {}, data = {}) {
    const url = `/${endpoints.files}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a file */
  updateFile(headers = {}, data = {}) {
    const url = `/${endpoints.file}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a file */
  deleteFile(headers = {}, params = {}) {
    const url = `/${endpoints.file}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of certificates */
  getCertificates(headers = {}, params = {}) {
    const url = `/${endpoints.certificates}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a certificate */
  createCertificate(headers = {}, data = {}) {
    const url = `/${endpoints.certificates}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a certificate */
  updateCertificate(headers = {}, data = {}) {
    const url = `/${endpoints.certificate}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a certificate */
  deleteCertificate(headers = {}, params = {}) {
    const url = `/${endpoints.certificate}`;
    return axiosClient.delete(url, { headers, params });
  }

  /* Returns a list of achievements */
  getAchievements(headers = {}, params = {}) {
    const url = `/${endpoints.achievements}`;
    return axiosClient.get(url, { headers, params });
  }

  /* Create a achievement */
  createAchievement(headers = {}, data = {}) {
    const url = `/${endpoints.achievements}`;
    return axiosClient.post(url, data, { headers });
  }

  /* Update a achievement */
  updateAchievement(headers = {}, data = {}) {
    const url = `/${endpoints.achievement}`;
    return axiosClient.patch(url, data, { headers });
  }

  /* Delete a achievement */
  deleteAchievement(headers = {}, params = {}) {
    const url = `/${endpoints.achievement}`;
    return axiosClient.delete(url, { headers, params });
  }
}

const coreService = new CoreService();
export default coreService;

