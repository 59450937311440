import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Navigate } from 'react-router-dom';
import InactiveIcon from "../../assets/images/inactive-icon.svg";
import userService from "../../services/user.service";
import { useTranslation } from 'react-i18next';

function Inactive() {
  const { t } = useTranslation();
  const [path, setPath] = useState({
    dashboard: true,
    webinars: false,
    calendar: false,
    inactive: false,
    courseComplete: false,
  });
  const user = userService.getUser();

  const resubButton = {
    background: "linear-gradient(90deg, #262261 0%, #6BCAD2 100%)",
    border: "none",
    width: "300px",
    height: "64px",
    alignText: "center",
    borderRadius: "100px",
    color: "#fff",
    fontWeight: "500",
    fontSize: "18px",
  };

  const supportButton = {
    width: "300px",
    height: "64px",
    alignText: "center",
    background: "transparent",
    border: "1px solid #6BCAD2",
    borderRadius: "100px",
    fontWeight: "500",
    fontSize: "18px",
  };

  const inactiveCardStyle = {
    width: "780px",
    minHeight: "480px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "4rem 2rem 2rem",
    position: "relative",
    maxWidth: "100%"
  };

  const inactiveContainerStyle = {
    height: "calc(100vh - 90px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    margin: "0 1rem"
  };

  const inactiveIconStyle = {
    height: "130px",
    width: "130px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    position: "absolute",
    top: "-65px",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "5px",
  };

  const inactiveIcon = {
    backgroundColor: "#6BCAD260",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const openLink = (e, url) => {
    e.preventDefault();
    window.location.href = url;
  };

  if (user && user.active) return <Navigate to="/" />;

  return (
    <div className="inactive">
      <Header path={path} setPath={setPath} />

      <div className="inactive_container" style={inactiveContainerStyle}>
        <div className="inactive__card" style={inactiveCardStyle}>
          <div className="inactive_icon_container" style={inactiveIconStyle}>
            <div className="inactive_icon" style={inactiveIcon}>
              <img src={InactiveIcon} alt="" />
            </div>
          </div>
          <span
            style={{
              fontSize: "28px",
              lineHeight: "35px",
              fontWeight: "500",
              color: "#21273c",
            }}
          >
            {t("inactive-subscription-title")}
          </span>
          <p
            style={{
              fontWeight: "400",
              fontSize: "20px",
              margin: "1rem 0 2rem 0",
              opacity: "0.7",
              color: "#21273D",
              lineHeight: "34px",
            }}
          >
            {t("inactive-subscription")}
          </p>
          <div
            className="button_wrapper"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "1rem",
              gap: "0.5rem"
            }}
          >
            <button style={resubButton} onClick={(e) => openLink(e, 'https://office2.nvisionu.com/app.html#/TeamDashboard')}>{t("resubscribe-button")}</button>
            <button style={supportButton} onClick={(e) => openLink(e, 'https://office2.nvisionu.com/app.html#/Home')}>{t("contact-button")}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inactive;
