import React from "react";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import VideoChat from "./VideoPlayer/VideoChat/VideoChat";

function ModuleHeader({module, set_video_ended, videoEnded, set_video_started, video_url}) {
  const headerClassName = ['module_header'];
    
  if(module.name){
      headerClassName.push(module.type);
  }

  return (
    <div className={headerClassName.join(' ')}>
        {<VideoPlayer module={module} set_video_ended={set_video_ended} set_video_started={set_video_started} video_url={video_url}/>}
        {module.type === 'webinar' && !module.completed && module.sessions.length > 0 && !module.sessions[0].completed ? (
            <VideoChat module={module} videoEnded={videoEnded} video_url={video_url} />
        ) : null}
    </div>
  )
}

export default ModuleHeader;
