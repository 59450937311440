import React, { useEffect, useState, useCallback } from "react";
import StatusCheck from "../StatusCheck/StatusCheck";
import ModulesAccordion from "../ModulesAccordion/ModulesAccordion";
import { useTranslation } from "react-i18next";
import coreService from "../../../../../../services/core.service";

function Weeks({
  lesson_title,
  lesson,
  status,
  slug,
  week,
  date_available,
  is_active,
  modules,
  parentModule,
  locked,
  activeSections,
  setActiveSections,
  setExpand
}) {
  const [modulesArray, setModulesArray] = useState([]);
  const [completeSection, setCompleteSection] = useState(false)
  const { t } = useTranslation();

  function formatDate(date) {
    const dt = new Date(Date.parse(date));
    return dt.toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric" });
  }

  const markLessonComplete = useCallback(
    () => {
      if(modulesArray && modulesArray.length > 0 && modulesArray[modulesArray.length - 1].completed) {
        setCompleteSection(true)
      }
    }, [modulesArray]);

  const loadModules = useCallback(() => {
    const params = { section: slug, limit: 100 };
    coreService.getModules({}, params).then((res) => {
      setModulesArray(() => res.data.records);
      const isSectionActive = !!res.data.records.find((x) => x.slug === parentModule.slug);
      setActiveSections((sections) => {
        return { ...sections, [slug]: isSectionActive } });
    });
  }, [parentModule.slug, setActiveSections, slug]);

  useEffect(() => {
    if (lesson.slug === parentModule.section) {
      loadModules();
    }
  }, [lesson, parentModule]);

  useEffect(() => {
    setModulesArray(modules);
  }, [lesson]);

  useEffect(() => {
    markLessonComplete()
  }, [markLessonComplete])

  return (
    <div
      className="lesson__sidebar__planner__weeks"
      style={locked || !is_active ? { color: "rgba(255, 255, 255, 0.5)" } : null}
    >
      {/* column for tickmark and line         */}

      <div className="lesson__sidebar__planner__weeks__grid_col_1">
        <StatusCheck status={status || completeSection} locked={!lesson.active}/>
        <div className="line"></div>
      </div>


      <div className="lesson__sidebar__planner__weeks__grid_col_2">
        <header>
          <span className="duration">
            <h6>{week}</h6>
          </span>
          <h6 style={!is_active ? { color: "rgba(255,255,255,1)" } : null}>
            {status
              ? t("Complete")
              : is_active
              ? ""
              : t("Available") + ' ' +  formatDate(date_available)}
          </h6>
        </header>

        <ModulesAccordion
          slug = { slug }
          lesson = { lesson }
          lesson_title = { lesson_title }
          modules_array = { modulesArray }
          set_modules_array = { setModulesArray }
          setActiveSections={setActiveSections}
          activeSections={activeSections}
          current_module = { parentModule }
          setExpand={setExpand}
        />
      </div>
    </div>
  );
}

export default Weeks;
